function similarProducts() {

    var similarproducts = document.getElementById('similar-products'),
        securityStats = document.querySelector('.security-stats');

    if (typeof similarproducts !== 'undefined' && similarproducts !== null && similarproducts.classList.contains('is-top')) {
        if (typeof securityStats !== 'undefined' && securityStats !== null) {
            securityStats.classList.add('has-similar-products');
            securityStats.parentNode.insertBefore( similarproducts, securityStats.nextElementSibling );
        } else {
            document.body.insertBefore( similarproducts, document.querySelector('.page-hero').nextSibling );
        }
    }
}

export default function init() {
    similarProducts();
}
